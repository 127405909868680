import { __awaiter, __generator } from "tslib";
import { getSearch } from '@/api/details/search';
import Pagination from '@/components/Pagination.vue';
export default {
    components: {
        Pagination: Pagination
    },
    data: function () {
        return {
            searchValue: '',
            selectValue: 1,
            searchList: [],
            searchTotal: 0,
            resultList: [],
            routerNameMap: {
                '1': 'productDetails',
                '2': 'appDetails',
                '4': 'newsDetails',
                '16': 'service'
            },
            categoryMap: {
                '1': this.$t('search.categoryMap1'),
                '2': this.$t('search.categoryMap2'),
                '4': this.$t('search.categoryMap3'),
                '16': this.$t('search.categoryMap4')
            },
            propsCnMap: {
                '产品中心': 1,
                '行业应用': 2,
                '新闻中心': 4,
                '服务支持': 16
            },
            propsEnMap: {
                'Product Center': 1,
                'Industry Application': 2,
                'News Center': 4,
                'Service Support': 16
            },
            page: 1,
            rows: 10,
            total: null
        };
    },
    watch: {
        page: function (val) {
            if (val !== 1) {
                this.fetchSearchList();
            }
        }
    },
    mounted: function () {
        this.searchValue = this.$route.query.value;
        this.selectValue = this.propsCnMap[this.$route.query.name] ||
            this.propsEnMap[this.$route.query.name] || 1;
        this.fetchSearchList();
    },
    methods: {
        fetchSearchList: function () {
            return __awaiter(this, void 0, void 0, function () {
                var query, data;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            query = {
                                page: this.page,
                                rows: this.rows,
                                description: this.searchValue,
                                category_id: this.selectValue
                            };
                            return [4, getSearch(query)];
                        case 1:
                            data = _a.sent();
                            data.rows.forEach(function (item) {
                                item.title = _this.categoryMap[item.category_id] + "(" + item.total + ")";
                                if (_this.selectValue === item.category_id) {
                                    _this.total = item.total;
                                }
                            });
                            this.searchList = data.rows;
                            this.resultList = data.rows.find(function (item) { return item.category_id === _this.selectValue; }).rows;
                            document.documentElement.scrollTop = 0;
                            return [2];
                    }
                });
            });
        },
        selectChange: function () {
            this.page = 1;
            this.fetchSearchList();
        },
        getRouter: function (item) {
            var name = this.routerNameMap[this.selectValue];
            return { name: name, params: { id: item.id } };
        }
    }
};
